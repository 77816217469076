<script>
import { scrollTo, scrollAnime } from '@/js/scrollUtils';

export default {
    name: "ProjectsComp",
    props: ['isLoading'],
    watch: {
        isLoading(val) {
            if (!val) {
                const isMobile = window.matchMedia("(max-width: 768px)").matches;
                if (!isMobile) { // PC
                    this.scrollAnime({
                        target: ".skills .skill:nth-child(1), .skills .skill:nth-child(3)",
                        direction: "top",
                        duration: 1500,
                        distance: "80px"
                    });
                    this.scrollAnime({
                        target: ".skills .skill:nth-child(2)",
                        direction: "bottom",
                        duration: 1500,
                        distance: "80px"
                    });
                } else { // MOBILE
                    this.scrollAnime({
                        target: ".skills .skill:nth-child(1), .skills .skill:nth-child(3)",
                        direction: "left",
                        duration: 1500,
                        distance: "80px"
                    });
                    this.scrollAnime({
                        target: ".skills .skill:nth-child(2)",
                        direction: "right",
                        duration: 1500,
                        distance: "80px"
                    });
                }
            }
        }
    },
    data() {
        return {
            darkMode: localStorage.getItem("darkMode") ? eval(localStorage.getItem("darkMode")) : true,
        };
    },
    mounted() {
        // Check if body classlist contains dark-mode
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.attributeName === "class") {
                    this.darkMode = document.body.classList.contains("dark-mode");
                }
            });
        });
        observer.observe(document.body, { attributes: true });
    },
    methods: {
        // Scroll method
        scrollTo, scrollAnime,
        toLink(link) {
            window.open(link, '_blank');
        }
    },
};
</script>

<template>
    <div class="skills">
        <div class="skill">
            <font-awesome-icon icon="code"/>
            <p>{{ $t("skills.code.title") }}</p>
            <p>{{ $t("skills.code.desc") }}</p>
        </div>
        <div class="skill">
            <font-awesome-icon icon="brain"/>
            <p>{{ $t("skills.ai.title") }}</p>
            <p>{{ $t("skills.ai.desc") }}</p>
        </div>
        <div class="skill">
            <font-awesome-icon icon="robot"/>
            <p>{{ $t("skills.auto.title") }}</p>
            <p>{{ $t("skills.auto.desc") }}</p>
        </div>
    </div>
</template>

<style scoped>
.skills {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    padding: 0 40px;
}
.skill {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 350px;
    max-width: 400px;
    margin: 0 40px;
}
.skill svg {
    background-color: #0092fd;
    color: white;
    padding: 20px;
    font-size: 20px;
    width: fit-content;
    aspect-ratio: 1/1;
    border-radius: 50px;
}
.skill p:first-of-type {
    font-size: 25px;
    font-weight: 600;
}
.skill p:last-of-type {
    font-weight: 500;
    line-height: 1.6;
}

/* MEDIA QUERIES */
@media screen and (max-width: 1100px) {
    .skills {
        padding: 0;
    }
    .skill {
        max-width: unset;
    }
}
@media screen and (max-width: 720px) {
    .skills {
        flex-direction: column;
    }
    .skill {
        min-width: unset;
        margin: 0 20px;
    }
}
</style>