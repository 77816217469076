import ScrollReveal from 'scrollreveal';

// scrollTo
export function scrollTo(target, offset = 0) {
    const element = document.getElementById(target);
    if (element) {
        const elementRect = element.getBoundingClientRect();
        const absoluteElementTop = elementRect.top + window.scrollY;
        const additionalScroll = absoluteElementTop + offset;
        window.scrollTo({
            top: additionalScroll,
            behavior: 'smooth'
        });
    }
}

// scrollAnime
export function scrollAnime(options) {
    ScrollReveal().reveal(options.target, {
        origin: options.direction,
        duration: options.duration,
        distance: options.distance,
        scale: options.scale ? options.scale : 1,
    });
}