<script>
import "./assets/css/main.css";
import LoaderComp from "./components/LoaderComp.vue";
import NavComp from "./components/NavComp.vue";
import HeroComp from "./components/HeroComp.vue";
import BiographyComp from "./components/BiographyComp.vue";
import ProjectsComp from "./components/ProjectsComp.vue";
import SkillsComp from "./components/SkillsComp.vue";
import ContactComp from "./components/ContactComp.vue";
import FooterComp from "./components/FooterComp.vue";

export default {
    name: "App",
    data() {
        return {
            isLoading: true
        };
    },
    mounted() {
        // Check if the page is loaded and set a minimum of 2s to show loader
        document.body.style.overflow = "hidden";
        const checkPageLoad = () => {
            if (document.readyState === "complete") {
                window.scrollTo(0, 0);
                this.isLoading = false;
                document.body.style.overflow = "auto";
                document.documentElement.style.scrollBehavior = "smooth";
            } else {
                setTimeout(checkPageLoad, 500);
            }
        };
        setTimeout(checkPageLoad, 1500);
    },
    components: { LoaderComp, NavComp, HeroComp, BiographyComp, ProjectsComp, SkillsComp, ContactComp, FooterComp },
};
</script>

<template>
    <LoaderComp v-show="isLoading" />
    <NavComp />
    <HeroComp :isLoading="isLoading"/>
    <div class="webcont">
        <BiographyComp :isLoading="isLoading"/>
        <ProjectsComp :isLoading="isLoading"/>
        <SkillsComp :isLoading="isLoading"/>
        <ContactComp :isLoading="isLoading"/>
        <FooterComp :isLoading="isLoading"/>
    </div>
</template>

<style>
/* APP */
#app {
    font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
}

.webcont {
    display: flex;
    flex-direction: column;
    gap: 200px;
}
</style>