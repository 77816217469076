<script>
export default {
    name: "LoaderComp",
    data() {
        return {
            darkMode: localStorage.getItem("darkMode") ? eval(localStorage.getItem("darkMode")) : true,
        }
    }
};
</script>

<template>
    <div class="pulse-container">
        <svg v-show='!darkMode' xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
            <path d="M83.2266 43H76.8789V28.3516C76.8789 27.5215 76.7324 26.7809 76.4395 26.1299C76.1628 25.4626 75.7803 24.8929 75.292 24.4209C74.8037 23.9489 74.2259 23.5908 73.5586 23.3467C72.9076 23.0863 72.1995 22.9561 71.4346 22.9561H60.3262V43H53.9785V19.7578C53.9785 19.3184 54.0599 18.9115 54.2227 18.5371C54.3854 18.1465 54.6133 17.8128 54.9062 17.5361C55.1992 17.2432 55.541 17.0153 55.9316 16.8525C56.3223 16.6898 56.7373 16.6084 57.1768 16.6084H71.4834C72.2809 16.6084 73.1191 16.6979 73.998 16.877C74.8932 17.056 75.7721 17.349 76.6348 17.7559C77.5137 18.1465 78.3438 18.651 79.125 19.2695C79.9225 19.8717 80.6224 20.6123 81.2246 21.4912C81.8431 22.3538 82.3314 23.3548 82.6895 24.4941C83.0475 25.6335 83.2266 26.9193 83.2266 28.3516V43Z" fill="black"/>
            <path d="M46.0801 34.0889C46.0801 35.1794 45.9417 36.1641 45.665 37.043C45.3883 37.9056 45.0221 38.6706 44.5664 39.3379C44.1107 39.9889 43.5817 40.5505 42.9795 41.0225C42.3773 41.4782 41.7425 41.8525 41.0752 42.1455C40.4242 42.4385 39.765 42.6582 39.0977 42.8047C38.4303 42.9349 37.7956 43 37.1934 43H25.7432V36.6523H37.1934C38.0397 36.6523 38.6745 36.4326 39.0977 35.9932C39.5208 35.5537 39.7324 34.9189 39.7324 34.0889V25.5439C39.7324 24.665 39.5127 24.014 39.0732 23.5908C38.6501 23.1676 38.0234 22.9561 37.1934 22.9561H25.792C24.9294 22.9561 24.2783 23.1758 23.8389 23.6152C23.3994 24.0384 23.1797 24.665 23.1797 25.4951V51.252H16.832V25.4951C16.832 24.4046 16.9704 23.4281 17.2471 22.5654C17.5238 21.7028 17.89 20.946 18.3457 20.2949C18.8177 19.6276 19.3548 19.0661 19.957 18.6104C20.5592 18.1383 21.1859 17.7559 21.8369 17.4629C22.5042 17.1699 23.1715 16.9583 23.8389 16.8281C24.5225 16.6816 25.1572 16.6084 25.7432 16.6084H37.1934C38.2839 16.6084 39.2604 16.7467 40.123 17.0234C40.9857 17.3001 41.7425 17.6663 42.3936 18.1221C43.0609 18.5778 43.6224 19.1068 44.0781 19.709C44.5501 20.3112 44.9326 20.946 45.2256 21.6133C45.5186 22.2643 45.7301 22.9235 45.8604 23.5908C46.0068 24.2581 46.0801 24.8929 46.0801 25.4951V34.0889Z" fill="black"/>
            <path d="M48.4727 57.1084C47.7565 59.3382 46.9183 61.5355 45.958 63.7002C44.9977 65.8649 43.891 67.932 42.6377 69.9014C41.4007 71.8545 40.0091 73.6693 38.4629 75.3457C36.9329 77.0059 35.2321 78.4463 33.3604 79.667C31.5049 80.8714 29.4785 81.8236 27.2812 82.5234C25.1003 83.207 22.7321 83.5488 20.1768 83.5488C19.7373 83.5488 19.3223 83.4674 18.9316 83.3047C18.541 83.1419 18.1992 82.9222 17.9062 82.6455C17.6133 82.3525 17.3854 82.0189 17.2227 81.6445C17.0599 81.2539 16.9785 80.8389 16.9785 80.3994V57.1084H23.3262V76.957C24.8724 76.957 26.3535 76.6234 27.7695 75.9561C29.2018 75.2887 30.5527 74.4098 31.8223 73.3193C33.0918 72.2126 34.2637 70.9593 35.3379 69.5596C36.4284 68.1598 37.3968 66.7275 38.2432 65.2627C39.0895 63.7816 39.8057 62.333 40.3916 60.917C40.9938 59.501 41.4414 58.2314 41.7344 57.1084H48.4727Z" fill="black"/>
            <path d="M83.1895 74.5391C83.1895 75.6296 83.0511 76.6061 82.7744 77.4688C82.4977 78.3477 82.1315 79.1126 81.6758 79.7637C81.2201 80.431 80.6911 80.9925 80.0889 81.4482C79.4867 81.9202 78.8519 82.3027 78.1846 82.5957C77.5335 82.8887 76.8662 83.1003 76.1826 83.2305C75.5153 83.377 74.8805 83.4502 74.2783 83.4502H62.8281V77.1025H74.2783C75.141 77.1025 75.7839 76.8828 76.207 76.4434C76.6302 76.0039 76.8418 75.3691 76.8418 74.5391V54.4951C76.8418 53.665 76.6302 53.0384 76.207 52.6152C75.7839 52.1758 75.141 51.9561 74.2783 51.9561H62.8281C62.0469 51.9561 61.4284 52.2002 60.9727 52.6885C60.5169 53.1605 60.2891 53.7627 60.2891 54.4951V63.0889C60.2891 63.9189 60.5088 64.5537 60.9482 64.9932C61.3877 65.4326 62.0306 65.6523 62.877 65.6523H74.2783V72H62.8281C62.2259 72 61.5911 71.9349 60.9238 71.8047C60.2565 71.6582 59.5892 71.4385 58.9219 71.1455C58.2708 70.8525 57.6442 70.4782 57.042 70.0225C56.4398 69.5505 55.9108 68.9889 55.4551 68.3379C54.9993 67.6706 54.6331 66.9056 54.3564 66.043C54.0798 65.1641 53.9414 64.1794 53.9414 63.0889V54.4951C53.9414 53.8929 54.0065 53.2581 54.1367 52.5908C54.2832 51.9235 54.5029 51.2643 54.7959 50.6133C55.0889 49.946 55.4632 49.3112 55.9189 48.709C56.391 48.1068 56.9525 47.5778 57.6035 47.1221C58.2708 46.6663 59.0358 46.3001 59.8984 46.0234C60.7611 45.7467 61.7376 45.6084 62.8281 45.6084H74.2783C74.8805 45.6084 75.5153 45.6816 76.1826 45.8281C76.8662 45.9583 77.5335 46.1699 78.1846 46.4629C78.8519 46.7559 79.4867 47.1383 80.0889 47.6104C80.6911 48.0661 81.2201 48.6276 81.6758 49.2949C82.1315 49.946 82.4977 50.7028 82.7744 51.5654C83.0511 52.4281 83.1895 53.4046 83.1895 54.4951V74.5391Z" fill="black"/>
        </svg>
        <svg v-show='darkMode' xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
            <path d="M83.2266 43H76.8789V28.3516C76.8789 27.5215 76.7324 26.7809 76.4395 26.1299C76.1628 25.4626 75.7803 24.8929 75.292 24.4209C74.8037 23.9489 74.2259 23.5908 73.5586 23.3467C72.9076 23.0863 72.1995 22.9561 71.4346 22.9561H60.3262V43H53.9785V19.7578C53.9785 19.3184 54.0599 18.9115 54.2227 18.5371C54.3854 18.1465 54.6133 17.8128 54.9062 17.5361C55.1992 17.2432 55.541 17.0153 55.9316 16.8525C56.3223 16.6898 56.7373 16.6084 57.1768 16.6084H71.4834C72.2809 16.6084 73.1191 16.6979 73.998 16.877C74.8932 17.056 75.7721 17.349 76.6348 17.7559C77.5137 18.1465 78.3438 18.651 79.125 19.2695C79.9225 19.8717 80.6224 20.6123 81.2246 21.4912C81.8431 22.3538 82.3314 23.3548 82.6895 24.4941C83.0475 25.6335 83.2266 26.9193 83.2266 28.3516V43Z" fill="white"/>
            <path d="M46.0801 34.0889C46.0801 35.1794 45.9417 36.1641 45.665 37.043C45.3883 37.9056 45.0221 38.6706 44.5664 39.3379C44.1107 39.9889 43.5817 40.5505 42.9795 41.0225C42.3773 41.4782 41.7425 41.8525 41.0752 42.1455C40.4242 42.4385 39.765 42.6582 39.0977 42.8047C38.4303 42.9349 37.7956 43 37.1934 43H25.7432V36.6523H37.1934C38.0397 36.6523 38.6745 36.4326 39.0977 35.9932C39.5208 35.5537 39.7324 34.9189 39.7324 34.0889V25.5439C39.7324 24.665 39.5127 24.014 39.0732 23.5908C38.6501 23.1676 38.0234 22.9561 37.1934 22.9561H25.792C24.9294 22.9561 24.2783 23.1758 23.8389 23.6152C23.3994 24.0384 23.1797 24.665 23.1797 25.4951V51.252H16.832V25.4951C16.832 24.4046 16.9704 23.4281 17.2471 22.5654C17.5238 21.7028 17.89 20.946 18.3457 20.2949C18.8177 19.6276 19.3548 19.0661 19.957 18.6104C20.5592 18.1383 21.1859 17.7559 21.8369 17.4629C22.5042 17.1699 23.1715 16.9583 23.8389 16.8281C24.5225 16.6816 25.1572 16.6084 25.7432 16.6084H37.1934C38.2839 16.6084 39.2604 16.7467 40.123 17.0234C40.9857 17.3001 41.7425 17.6663 42.3936 18.1221C43.0609 18.5778 43.6224 19.1068 44.0781 19.709C44.5501 20.3112 44.9326 20.946 45.2256 21.6133C45.5186 22.2643 45.7301 22.9235 45.8604 23.5908C46.0068 24.2581 46.0801 24.8929 46.0801 25.4951V34.0889Z" fill="white"/>
            <path d="M48.4727 57.1084C47.7565 59.3382 46.9183 61.5355 45.958 63.7002C44.9977 65.8649 43.891 67.932 42.6377 69.9014C41.4007 71.8545 40.0091 73.6693 38.4629 75.3457C36.9329 77.0059 35.2321 78.4463 33.3604 79.667C31.5049 80.8714 29.4785 81.8236 27.2812 82.5234C25.1003 83.207 22.7321 83.5488 20.1768 83.5488C19.7373 83.5488 19.3223 83.4674 18.9316 83.3047C18.541 83.1419 18.1992 82.9222 17.9062 82.6455C17.6133 82.3525 17.3854 82.0189 17.2227 81.6445C17.0599 81.2539 16.9785 80.8389 16.9785 80.3994V57.1084H23.3262V76.957C24.8724 76.957 26.3535 76.6234 27.7695 75.9561C29.2018 75.2887 30.5527 74.4098 31.8223 73.3193C33.0918 72.2126 34.2637 70.9593 35.3379 69.5596C36.4284 68.1598 37.3968 66.7275 38.2432 65.2627C39.0895 63.7816 39.8057 62.333 40.3916 60.917C40.9938 59.501 41.4414 58.2314 41.7344 57.1084H48.4727Z" fill="white"/>
            <path d="M83.1895 74.5391C83.1895 75.6296 83.0511 76.6061 82.7744 77.4688C82.4977 78.3477 82.1315 79.1126 81.6758 79.7637C81.2201 80.431 80.6911 80.9925 80.0889 81.4482C79.4867 81.9202 78.8519 82.3027 78.1846 82.5957C77.5335 82.8887 76.8662 83.1003 76.1826 83.2305C75.5153 83.377 74.8805 83.4502 74.2783 83.4502H62.8281V77.1025H74.2783C75.141 77.1025 75.7839 76.8828 76.207 76.4434C76.6302 76.0039 76.8418 75.3691 76.8418 74.5391V54.4951C76.8418 53.665 76.6302 53.0384 76.207 52.6152C75.7839 52.1758 75.141 51.9561 74.2783 51.9561H62.8281C62.0469 51.9561 61.4284 52.2002 60.9727 52.6885C60.5169 53.1605 60.2891 53.7627 60.2891 54.4951V63.0889C60.2891 63.9189 60.5088 64.5537 60.9482 64.9932C61.3877 65.4326 62.0306 65.6523 62.877 65.6523H74.2783V72H62.8281C62.2259 72 61.5911 71.9349 60.9238 71.8047C60.2565 71.6582 59.5892 71.4385 58.9219 71.1455C58.2708 70.8525 57.6442 70.4782 57.042 70.0225C56.4398 69.5505 55.9108 68.9889 55.4551 68.3379C54.9993 67.6706 54.6331 66.9056 54.3564 66.043C54.0798 65.1641 53.9414 64.1794 53.9414 63.0889V54.4951C53.9414 53.8929 54.0065 53.2581 54.1367 52.5908C54.2832 51.9235 54.5029 51.2643 54.7959 50.6133C55.0889 49.946 55.4632 49.3112 55.9189 48.709C56.391 48.1068 56.9525 47.5778 57.6035 47.1221C58.2708 46.6663 59.0358 46.3001 59.8984 46.0234C60.7611 45.7467 61.7376 45.6084 62.8281 45.6084H74.2783C74.8805 45.6084 75.5153 45.6816 76.1826 45.8281C76.8662 45.9583 77.5335 46.1699 78.1846 46.4629C78.8519 46.7559 79.4867 47.1383 80.0889 47.6104C80.6911 48.0661 81.2201 48.6276 81.6758 49.2949C82.1315 49.946 82.4977 50.7028 82.7744 51.5654C83.0511 52.4281 83.1895 53.4046 83.1895 54.4951V74.5391Z" fill="white"/>
        </svg>
        <div class="pulse"></div>
    </div>
</template>

<style scoped>
.pulse-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: white;
    z-index: 99999999;
}
body.dark-mode .pulse-container {
    background-color: black;
}
.pulse {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 130px;
    height: 130px;
    border-radius: 50%;
    animation: pulse-animation 1.2s infinite ease-in-out;
    background-color: rgba(0, 0, 0, 0.2);
}
body.dark-mode .pulse {
    background-color: rgba(255, 255, 255, 0.2);
}
@keyframes pulse-animation {
    0% {
        transform: scale(0.9);
        opacity: 0.7;
    }
    50% {
        transform: scale(1);
        opacity: 0.3;
    }
    100% {
        transform: scale(0.9);
        opacity: 0.7;
    }
}
</style>