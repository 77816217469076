<script>
import { scrollAnime } from '@/js/scrollUtils';
import TitleComp from './TitleComp.vue';
import ButtonComp from './ButtonComp.vue';
import emailjs from 'emailjs-com';

export default {
    name: "ContactComp",
    props: ['isLoading'],
    watch: {
        isLoading(val) {
            if (!val) {
                this.scrollAnime({
                    target: ".contact-content .input1",
                    direction: "left",
                    duration: 1500,
                    distance: "80px",
                });
                this.scrollAnime({
                    target: ".contact-content .input2",
                    direction: "right",
                    duration: 1500,
                    distance: "80px"
                });
                this.scrollAnime({
                    target: ".contact-content .input3",
                    direction: "bottom",
                    duration: 1500,
                    distance: "80px"
                });
                this.scrollAnime({
                    target: ".contact-content button",
                    direction: "left",
                    duration: 1500,
                    distance: "80px"
                });
            }
        }
    },
    data() {
        return {
            name: "",
            email: "",
            message: "",
            maxLengthMessage: 1500,
            alertType: "",
        }
    },
    updated() {
		// Check if textarea exists and adjust his height
		if (this.$refs.textarea) {
			this.adjustTextareaHeight({
				target: this.$refs.textarea
			});
		}
	},
    methods: {
        scrollAnime,
        // Method to send message
        sendMessage() {
            // Check vars
            if (this.email.length < 3 || this.name.length == 0 || this.message.length == 0) {
                this.alertType = 'error';
                return;
            }

            // Check waiting time
            const lastSendTime = parseInt(localStorage.getItem('lastSendTime') || '0');
            const currentTime = new Date().getTime();
            if (currentTime - lastSendTime < 5 * 60 * 1000) { // 5 minutes
                this.alertType = 'wait';
                return;
            }

            // Trying to send email
            try {
                emailjs.send("service_ikflyva", "template_yyl6i2l", {
                    email: this.email,
                    name: this.name,
                    message: this.message,
                }, 'mbIHcasIgLXISmhK5');
                this.alertType = 'success';

                // Reset form field
                this.name = '';
                this.email = '';
                this.message = '';
                this.adjustTextareaHeight();

                // Save last send time
                localStorage.setItem('lastSendTime', currentTime.toString());
            } catch(error) {
                this.alertType = 'error';
            }
        },
        // Method to adjust textarea height
		adjustTextareaHeight() {
            const textarea = this.$refs.textarea;
			textarea.style.height = 'auto';
			textarea.style.height = `${textarea.scrollHeight}px`;
			textarea.style.minHeight = `${textarea.scrollHeight}px`;
		},
    },
    components: { TitleComp, ButtonComp }
};
</script>

<template>
    <div class="contact" id="contact">
        <TitleComp :title="$t('contact.title')" :subtitle="$t('contact.subtitle')" :isLoading="isLoading"/>
        <div class="alert" :class="alertType == 'success' ? 'alert-success' : (alertType == 'error' ? 'alert-error' : '')" v-if="alertType.length > 0">
            {{ alertType === 'success' ? this.$i18n.t("contact.alert.success") : (alertType === 'error' ? this.$i18n.t("contact.alert.error") : this.$i18n.t("contact.alert.wait")) }}
        </div>
        <div class="contact-content">
            <form @submit.prevent="sendMessage">
                <div>
                    <div class="form-input input1">
                        <label for="name">{{ $t('contact.name') }}</label>
                        <input id="name" type="text" v-model="name" required/>
                        <font-awesome-icon icon="xmark" @click="name = ''" v-if="name.length != ''"/>
                    </div>
                    <div class="form-input input2">
                        <label for="email">{{ $t('contact.email') }}</label>
                        <input id="email" type="email" v-model="email" required/>
                        <font-awesome-icon icon="xmark" @click="email = ''" v-if="email.length != ''"/>
                    </div>
                </div>
                <div class="form-input input3">
                    <label for="message">{{ $t('contact.message') }}<span>{{ message.length }} / {{ maxLengthMessage }}</span></label>
                    <textarea ref="textarea" id="message" type="text" v-model="message" @input="adjustTextareaHeight" :maxlength="maxLengthMessage" @change="adjustTextareaHeight" @focus="adjustTextareaHeight" required></textarea>
                    <font-awesome-icon icon="xmark" @click="message = ''" v-if="message.length != ''"/>
                </div>
                <ButtonComp :title="$t('contact.button')" type="submit" />
            </form>
        </div>
    </div>
</template>

<style scoped>
.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 40px;
}
.alert {
    box-sizing: border-box;
    width: 100%;
    max-width: 1000px;
    padding: 15px;
    margin-bottom: 15px;
    color: white;
    font-weight: 500;
    background-color: #0092fd;
}
.alert-error {
    background-color: rgb(175 56 56) !important;
}
.alert-success {
    background-color: rgb(51 135 62) !important;
}
.contact-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
form {
    max-width: 1000px;
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
}
form > div:not(.form-input) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}
.form-input {
    position: relative;
    font-weight: 500 !important;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.form-input svg {
    cursor: pointer;
    position: absolute;
    right: 13px;
    top: 42px;
}
label {
    display: flex;
    justify-content: space-between;
}
input, textarea {
    font-weight: 500 !important;
    padding: 13px 36px 13px 13px;
    font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
    border: none;
    background-color: #ededed;
}
input:focus-visible, textarea:focus-visible {
    outline: 2px solid #0092fd !important;
}
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #ededed inset !important;
    -webkit-text-fill-color: black !important;
}
body.dark-mode input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #242424 inset !important;
    -webkit-text-fill-color: white !important;
}
body.dark-mode input, body.dark-mode textarea {
    background-color: #111111;
}
textarea {
	overflow-y: hidden;
    box-sizing: border-box;
    min-width: 100%;
	height: auto;
	min-height: 285px;
    resize: none;
}

/* MEDIA QUERIES */
@media screen and (max-width: 720px) {
    .contact {
        margin: 0 20px;
    }
}
</style>