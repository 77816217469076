<script>
import { scrollAnime } from '@/js/scrollUtils';

export default {
    name: "TitleComp",
    props: ["title", "subtitle", "isLoading"],
    watch: {
        isLoading(val) {
            if (!val) {
                this.scrollAnime({
                    target: ".title",
                    direction: "top",
                    duration: 1000,
                    distance: "70px"
                });
            }
        }
    },
    methods: {
        scrollAnime,
    },
};
</script>

<template>
    <div class="title">
        <div>
            <p>{{ subtitle }}</p>
            <p>{{ title }}</p>
        </div>
        <hr />
    </div>
</template>

<style scoped>
div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
div p:first-child {
    color: #0092fd;
    font-weight: bold;
}
div p:last-child {
    font-size: 2em;
    font-weight: 500;
}
hr {
    margin: 20px 0 35px 0;
    width: 2px;
    height: 50px;
    background: #0092fd;
    border: none;
    border-radius: 20px;
}
</style>