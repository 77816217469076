<script>
export default {
    name: "ButtonComp",
    props: ['title']
};
</script>

<template>
    <button>{{ title }}<font-awesome-icon icon="angle-right" /></button>
</template>

<style scoped>
button {
    position: relative;
    font-family: "Montserrat", Helvetica, Arial, Lucida, sans-serif;
    font-weight: 500;
    font-size: 16px;
    user-select: none;
    cursor: pointer;
    border: none;
    border-bottom: 2px solid;
    padding: 10px 30px 10px 10px;
    transition: padding .5s ease-in-out;
    background-color: transparent;
    display: flex;
    align-items: center;
}
button:hover {
    padding: 10px 42px 10px 20px;
}
button svg {
    position: absolute;
    right: 20px;
    opacity: 0;
    color: #0092fd;
    transition: all .5s ease-in-out;
}
button:hover svg {
    opacity: 1;
}
</style>